
import { defineComponent } from '@vue/composition-api';
import FCImage from '~/components/FCImage.vue';

export default defineComponent({
  name: 'SizeModal',
  components: {
    FCImage,
  },
});
